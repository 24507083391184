<template>
  <div>
    <div style="margin-bottom: 10px">
      <label for="">{{ label }}</label>
      <span class="" style="margin-left: 20px;" @click="toggleDictation">
        <svg v-if="!isListening" width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M20 12V13C20 17.4183 16.4183 21 12 21C7.58172 21 4 17.4183 4 13V12M12 17C9.79086 17 8 15.2091 8 13V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V13C16 15.2091 14.2091 17 12 17Z"
              stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-else width="25px" height="25px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M16 10.4V7.00003C16 4.79089 14.2091 3.00003 12 3.00003C11.0406 3.00003 10.1601 3.3378 9.47086 3.90092M4 12V13C4 17.4183 7.58172 21 12 21C14.4653 21 16.6701 19.8849 18.1376 18.1316M3 3L21 21M12 17C9.79086 17 8 15.2092 8 13V8.00003L15.2815 15.288C14.5585 16.323 13.3583 17 12 17Z"
              stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </span>
    </div>
    <b-form-textarea
        v-model="text"
        :id="textareaId"
        :placeholder="placeholder"
        :rows="rows"
        :readonly="readonly"
        :max-rows="maxRows"
    />
  </div>
</template>

<script>
import { BFormTextarea } from "bootstrap-vue";

export default {
  components: {BFormTextarea},
  props: {
    value: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    maxRows: {
      type: String,
      default: '999999'
    },
    label: {
      type: String,
      default: ''
    },
    textareaId: {
      type: String,
      default: 'textarea-default'
    },
    placeholder: {
      type: String,
      default: 'Notas'
    },
    rows: {
      type: String,
      default: '3'
    }
  },
  data() {
    return {
      text: this.value === null ? '' : this.value,
      recognition: null,
      isListening: false,
      manuallyStopped: false,
      interimTranscript: ''
    };
  },
  created() {
    if (this.value === null) {
      this.text = ''
    }
  },
  watch: {
    text(newValue) {
      this.$emit('input', newValue);
    },
    value(newValue) {
      this.text = newValue;
    }
  },
  mounted() {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      this.recognition = new SpeechRecognition();
      this.recognition.lang = 'es-MX';
      this.recognition.continuous = true;
      this.recognition.interimResults = true;

      this.recognition.onresult = (event) => {
        let interimTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            this.text += this.cleanTranscript(transcript) + ' ';
          } else {
            interimTranscript += transcript;
          }
        }
        this.interimTranscript = interimTranscript;
      };

      this.recognition.onend = () => {
        if (!this.manuallyStopped) {
          this.recognition.start();
        }
        this.isListening = false;
      };

      this.recognition.onerror = (event) => {
        console.error(event.error);
        this.isListening = false;
      };
    } else {
      console.warn('Este navegador no soporta la API de reconocimiento de voz');
    }
  },
  methods: {
    toggleDictation() {
      if (this.recognition) {
        if (this.isListening) {
          this.manuallyStopped = true;
          this.recognition.stop();
          this.isListening = false;
        } else {
          this.manuallyStopped = false;
          this.recognition.start();
          this.isListening = true;
        }
      } else {
        alert('El reconocimiento de voz no está disponible en este navegador.');
      }
    },
    cleanTranscript(transcript) {
      return transcript.split(' ').filter(word => word.toLowerCase() !== 'null').join(' ');
    }
  },
};
</script>

<style>
.voice-input {
  position: relative;
}

.voice-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #007bff;
}

.voice-icon i {
  font-size: 1.2em;
}
</style>